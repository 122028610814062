<template>
  <v-container
    id="exports-view"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <!-- ToDo: Can I add a message that is controlled by the assemblyItems isBusyAll switch? Would -->
          <!-- ToDo: like to display a message that the export data is being retrieved while we wait on  -->
          <!-- ToDo: the export button to be available.                                                  -->
          <template #title>
            {{ appItem.title }} — <small class="text-body-1">List of exports</small>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  sm="8"
                >
                  <select-customer
                    id="2-maintain-customer"
                    v-model="customers.selectedItem"
                    :loading="customers.isBusy"
                    :appendhint="`to select from its ${displayAsPluralPlan}`"
                  />
                </v-col>
                <v-col
                  sm="4"
                >
                  <div
                    v-if="assemblyItems.isBusyAll === true || plans.isBusy === true"
                    class="text-center primary--text pt-7"
                  >
                    Gathering export data, please wait......
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  sm="8"
                >
                  <select-plan
                    id="2-maintain-plan"
                    v-model="plans.selectedItem"
                    :loading="plans.isBusy"
                    appendhint=""
                  />
                </v-col>
                <v-col
                  sm="1"
                  offset-sm="3"
                  class="text-right pt-5"
                >
                  <xlsx-workbook>
                    <xlsx-sheet
                      v-for="sheet in sheets"
                      :key="sheet.index"
                      :collection="sheet.data"
                      :sheet-name="sheet.name"
                    />
                    <!--  We want to make sure prompts is finished loading before we allow the user to export. -->
                    <!--  That is why we are checking for prompts.busy versus plans.busy                       -->
                    <xlsx-download>
                      <v-btn
                        color="primary"
                        :disabled="assemblyItems.isBusyAll === true"
                        @click="loadExportData"
                      >
                        Export
                      </v-btn>
                    </xlsx-download>
                  </xlsx-workbook>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'

  export default {
    name: 'ExportsView',
    components: {
      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        appItem: null,
        sheets: [],
      }
    },
    computed: {
      displayAsPluralPlan: get('plan/displayAsPlural'),
      customers: get('customer/customers'),
      categories: get('category/categories'),
      parts: get('part/parts'),
      deliveryloads: get('deliveryload/deliveryloads'),
      usages: get('usage/usages'),
      promptGroups: get('promptgroup/promptGroups'),
      prompts: get('prompt/prompts'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      assemblies: get('assembly/assemblies'),
      assemblyItems: get('assemblyitem/assemblyItems'),

      ...mapGetters('app', [
        'getAppItem',
        'getDataById',
      ]),
    },

    created () {
      this.initialize()
      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/setSelected':
            this.$store.dispatch('prompt/retrieveAll')
            this.$store.dispatch('assemblyitem/retrieveAll')
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      initialize () {
        this.appItem = this.getAppItem(this.$options.name)
        this.$store.dispatch('user/setPageName', this.$options.name)
        this.$store.dispatch('prompt/retrieveAll')
        this.$store.dispatch('assemblyitem/retrieveAll')
      },

      async loadExportData () {
        this.sheets = []
        const selectedPlanId = this.plans.selectedItem.id

        // eslint-disable-next-line camelcase
        const categoriesData = await this.categories.data.map(({ vendor_id, description }) => ({
          [this.categories.tabbedColumns.id_header]: vendor_id,
          [this.categories.tabbedColumns.description_header]: description,
        }))

        // eslint-disable-next-line camelcase
        const partsData = await this.parts.all.map(({ vendor_id, part_id, description, special_order, unit }) => ({
          [this.categories.tabbedColumns.id_header]: vendor_id,
          [this.parts.tabbedColumns.id_header]: part_id,
          [this.parts.tabbedColumns.description_header]: description,
          [this.parts.tabbedColumns.special_order_header]: special_order,
          [this.parts.tabbedColumns.unit_header]: unit,
        }))

        // eslint-disable-next-line camelcase
        const deliveryloadsData = await this.deliveryloads.data.map(({ load_id, description }) => ({
          [this.deliveryloads.tabbedColumns.id_header]: load_id,
          [this.deliveryloads.tabbedColumns.description_header]: description,
        }))

        // eslint-disable-next-line camelcase
        const usagesData = await this.usages.data.map(({ usage_id, description }) => ({
          [this.usages.tabbedColumns.id_header]: usage_id,
          [this.usages.tabbedColumns.description_header]: description,
        }))

        // eslint-disable-next-line camelcase
        const promptgroupsData = await this.promptGroups.data.map(({ pv_id, description }) => ({
          [this.promptGroups.tabbedColumns.id_header]: pv_id,
          [this.promptGroups.tabbedColumns.description_header]: description,
        }))

        // eslint-disable-next-line camelcase
        const promptsData = await this.prompts.all.map(({ pv_id, description, variable, sort_order }) => ({
          [this.promptGroups.tabbedColumns.id_header]: pv_id,
          [this.prompts.tabbedColumns.description_header]: description,
          [this.prompts.tabbedColumns.id_header]: variable,
          [this.prompts.tabbedColumns.sortOrder_header]: sort_order,
        }))

        const plansFiltered = this.plans.data.filter(function (plansArray) {
          return plansArray.plan_id === selectedPlanId
        })
        // eslint-disable-next-line camelcase
        const plansData = await plansFiltered.map(({ plan_id, description }) => ({
          [this.plans.tabbedColumns.id_header]: plan_id,
          [this.plans.tabbedColumns.description_header]: description,
        }))

        // eslint-disable-next-line camelcase
        const divisionsData = await this.divisions.data.map(({ plan_id, phase_id, description }) => ({
          [this.plans.tabbedColumns.id_header]: plan_id,
          [this.divisions.tabbedColumns.id_header]: phase_id,
          [this.divisions.tabbedColumns.description_header]: description,
        }))

        const assembliesFiltered = this.assemblies.all.filter(function (assemblyArray) {
          return assemblyArray.plan_id === selectedPlanId
        })
        // eslint-disable-next-line camelcase
        const assembliesData = await assembliesFiltered.map(({ plan_id, phase_id, assembly_id, description, pv_id }) => ({
          [this.plans.tabbedColumns.id_header]: plan_id,
          [this.divisions.tabbedColumns.id_header]: phase_id,
          [this.assemblies.tabbedColumns.id_header]: assembly_id,
          [this.assemblies.tabbedColumns.description_header]: description,
          [this.promptGroups.tabbedColumns.id_header]: pv_id,
        }))

        // eslint-disable-next-line camelcase
        const assemblyItemsData = await this.assemblyItems.all.map(({ plan_id, phase_id, assembly_id, sort_order, description, vendor_id, part_id, delivery_load_id, usage_id, waste_factor, formula }) => ({
          [this.plans.tabbedColumns.id_header]: plan_id,
          [this.divisions.tabbedColumns.id_header]: phase_id,
          [this.assemblies.tabbedColumns.id_header]: assembly_id,
          [this.assemblyItems.tabbedColumns.sort_order_header]: sort_order,
          [this.assemblyItems.tabbedColumns.description_header]: description,
          [this.categories.tabbedColumns.id_header]: vendor_id,
          [this.parts.tabbedColumns.id_header]: part_id,
          [this.deliveryloads.tabbedColumns.id_header]: delivery_load_id,
          [this.usages.tabbedColumns.id_header]: usage_id,
          [this.assemblyItems.tabbedColumns.waste_header]: waste_factor,
          [this.assemblyItems.tabbedColumns.formula_header]: formula,
        }))

        if (categoriesData.length > 0) {
          this.sheets.push({ name: 'Categories', data: categoriesData })
        }
        if (partsData.length > 0) {
          this.sheets.push({ name: 'Parts', data: partsData })
        }
        if (deliveryloadsData.length > 0) {
          this.sheets.push({ name: 'Delivery Loads', data: deliveryloadsData })
        }
        if (usagesData.length > 0) {
          this.sheets.push({ name: 'Usages', data: usagesData })
        }
        if (promptgroupsData.length > 0) {
          this.sheets.push({ name: 'Prompt Groups', data: promptgroupsData })
        }
        if (promptsData.length > 0) {
          this.sheets.push({ name: 'Prompts', data: promptsData })
        }
        if (plansData.length > 0) {
          this.sheets.push({ name: 'Plans', data: plansData })
        }
        if (divisionsData.length > 0) {
          this.sheets.push({ name: 'Divisions', data: divisionsData })
        }
        if (assembliesData.length > 0) {
          this.sheets.push({ name: 'Assemblies', data: assembliesData })
        }
        if (assemblyItemsData.length > 0) {
          this.sheets.push({ name: 'Assembly Items', data: assemblyItemsData })
        }
      },

    },
  }
</script>
